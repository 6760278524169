import axios from "axios";

var url;

url = process.env.REACT_APP_API_ENDPOINT;

const api = axios.create({
  baseURL: url,
  headers: {
    // "Cache-Control": "no-cache",
    // Pragma: "no-cache",
    // Expires: "0",
    // 'Access-Control-Allow-Origin' : '*',
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST'
  },
});

const loadLanguage = localStorage.getItem("@wizeoTradeRede:lang"); 

if (loadLanguage) {
  api.defaults.headers.common["Accept-Language"] = loadLanguage?.replaceAll(
    '"',
    ""
  );
} else {
  api.defaults.headers.common["Accept-Language"] = "pt_BR";
}

if (localStorage.getItem("@wizeoTradeRede:token")) {
  api.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("@wizeoTradeRede:token")?.replaceAll('"', "");
}

export default api;
